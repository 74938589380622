import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BlogPostsCard } from '../../components/blog/card'
import { CategoriesFilterPanel } from '../../components/blog/categories-filter-panel'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import img1 from './post-1.jpg'
import img2 from './post-2.jpg'
import img3 from './post-3.jpg'
import img4 from './post-4.jpg'
import img5 from './post-5.jpg'
import img6 from './post-6.jpg'
import img7 from './post-7.jpg'
import img8 from './Jak_efektivně_5_kg.png'
import img9 from './images/zdrava-krabicka-denní-menu.jpg'
import img10 from './images/zdrave-jidlo-praha.jpg'
import img11 from './Jak_zhubnout_10_kg.png'
import img12 from './Zhubnout_bez_sportu.png'
import img13 from './images/3-min.jpg'
import img14 from './images/5-min.jpg'
import img15 from './images/4-min.jpg'
import img16 from './images/7-min.jpg'
import img17 from './images/8-min.jpg'
import img18 from './images/2-min.jpg'
import img19 from './images/6-min.jpg'
import img20 from './images/s-jakym-sportem-nejrychleji-zhubnu.jpg'
import img21 from './images/muze-za-nadvahu-konzumace-sacharidu.jpg'
import img22 from './images/krabickova-dieta-moznost-jzhubnout.jpg'
import img23 from './images/co-je-to-jojo-efekt-a-jak-s-nim-zatocit.jpg'
import img24 from './images/zelezo-proc-je-v-dulezite.jpg'
import img25 from './images/potrebujete-nabrat-svaly.jpg'
import img26 from './images/low-fat-low-carb-pomohou-zhubnout.jpg'
import img27 from './images/jsou-krabicky-vhodne-pro-kojici-zeny.jpg'
import img28 from './images/oleje-jak-je-pouzivat-v-kuchyni.jpg'
import img29 from './images/jaky-je-spravny-trojpomer-zivin.jpg'
import img30 from './images/chcete-udrzet-vahu-krabickovou-dietu.jpg'
import img31 from './images/sirupy.jpg'
import img32 from './images/krabickova-dieta-usetri-cas-i-penize.jpg'
import img33 from './images/je-nutne-suplementovat-horcik.jpg'
import img34 from './images/3-tipy-jak-jist-abyste-hubli.jpg'
import img35 from './images/veganska-strava-je-zdravejsi.jpg'
import img36 from './images/5-tipu-jak-hubnout-i-bez-diet.jpg'
import img37 from './images/how-to-healthy-baking.jpg'
import img38 from './images/lose-weight.jpg'
import img41 from './images/shutterstock_1049157986.jpg'
import img40 from './images/shutterstock_526305043.jpg'
import img39 from './images/shutterstock_1685806537.jpg'
import img42 from './images/shutterstock_1835733853-min.jpg'
import img43 from './images/shutterstock_1081128197-min.jpg'
import img44 from './images/blog33.jpg'
import img45 from './images/blog34.jpg'
import img46 from './images/blog35.jpg'
import img47 from './images/blog32.jpg'
import img48 from './images/blog37.jpg'
import img49 from './images/blog38.jpg'
import img50 from './images/blog39.jpg'
import img51 from './images/blog40.jpg'




import styles from './posts-grid.module.css'

const Posts = () => (
  <Container className={styles.wrapper}>
    <Breadcrumbs>
      <BreadcrumbsItem link="/">
        <Trans i18nKey="blog.link">Domu</Trans>
      </BreadcrumbsItem>
      <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
    </Breadcrumbs>
    <h3 className="fancyUnderlineText sectionTitleNew text-center">
      <Trans i18nKey="blog.title">
        Poslední <span>Články</span>
      </Trans>
    </h3>
    {/* <CategoriesFilterPanel /> */}
    <div className={styles.postsGrid}>
    <BlogPostsCard
        img={img51}
        date="13.09.23"
        title="Jaký je správný pitný režim?"
        category="Všechno"
        link="/blog/drinking-regime"
      />
    <BlogPostsCard
        img={img50}
        date="13.09.23"
        title="Jak si vybírat jídlo v restauracích?"
        category="Všechno"
        link="/blog/food-choose-restaurant"
      />
    <BlogPostsCard
        img={img49}
        date="13.09.23"
        title="Vláknina - pomůže vám s hubnutím?"
        category="Všechno"
        link="/blog/fiber-lose-weight"
      />
    <BlogPostsCard
        img={img48}
        date="12.09.23"
        title="Je palmový tuk zdraví škodlivý?"
        category="Všechno"
        link="/blog/palm-oil"
      />
    <BlogPostsCard
        img={img47}
        date="12.09.23"
        title="Kardio nebo silový trénink. Co je lepší na hubnutí?"
        category="Všechno"
        link="/blog/cardio-strength-loosing-weight"
      />
    <BlogPostsCard
        img={img46}
        date="12.09.23"
        title="Jaké účinky má ostropestřec? "
        category="Všechno"
        link="/blog/milk-thistle"
      />
      <BlogPostsCard
        img={img45}
        date="12.09.23"
        title="Proč si dávat pozor na ovocné šťávy?
        "
        category="Všechno"
        link="/blog/suitable-snacks"
      />
      <BlogPostsCard
        img={img44}
        date="12.09.23"
        title="Jaké svačiny jsou pro vás vhodné?"
        category="Všechno"
        link="/blog/right-snacks"
      />
      <BlogPostsCard
        img={img43}
        date="09.06.23"
        title="Škodí sladidla našemu zdraví?"
        category="Všechno"
        link="/blog/are-sweeteners-harmful-for-health"
      />
      <BlogPostsCard
        img={img42}
        date="09.06.23"
        title="Chcete zhubnout rychle? Nedělejte to"
        category="Všechno"
        link="/blog/how-to-lose-weight-fast"
      />
      <BlogPostsCard
        img={img41}
        date="05.06.23"
        title="Kávový souboj - která káva je zdravější?"
        category="Všechno"
        link="/blog/coffee-battle-which-coffee-is-healthier"
      />
      <BlogPostsCard
        img={img40}
        date="05.06.23"
        title="Proč byste měli suplementovat rybí olej?"
        category="Všechno"
        link="/blog/why-should-you-supplement-with-fish-oil"
      />
      <BlogPostsCard
        img={img39}
        date="05.06.23"
        title="Jak jíst o víkendu"
        category="Všechno"
        link="/blog/how-to-eat-on-the-weekend"
      />
      <BlogPostsCard
        img={img38}
        date="23.05.23"
        title="Zhubněte s námi do plavek"
        category="Všechno"
        link="/blog/lose-weight"
      />
      <BlogPostsCard
        img={img37}
        date="23.05.23"
        title="Jak na zdravé pečení?"
        category="Všechno"
        link="/blog/how-to-healthy-baking"
      />
      <BlogPostsCard
        img={img36}
        date="17.04.23"
        title="5 tipů, jak hubnout i bez diet"
        category="Všechno"
        link="/blog/5-tipu-jak-hubnout-i-bez-diet"
      />
      <BlogPostsCard
        img={img35}
        date="17.04.23"
        title="Veganská strava je zdravější. Nebo ne?"
        category="Všechno"
        link="/blog/veganska-strava-je-zdravejsi"
      />
      <BlogPostsCard
        img={img34}
        date="17.04.23"
        title="3 tipy, jak jíst, abyste efektivně hubli"
        category="Všechno"
        link="/blog/3-tipy-jak-jist-abyste-hubli"
      />
      <BlogPostsCard
        img={img33}
        date="17.04.23"
        title="Je nutné suplementovat hořčík?"
        category="Všechno"
        link="/blog/je-nutne-suplementovat-horcik"
      />
      <BlogPostsCard
        img={img32}
        date="17.04.23"
        title="Jak krabičková dieta ušetří čas i peníze"
        category="Všechno"
        link="/blog/krabickova-dieta-usetri-cas-i-penize"
      />
      <BlogPostsCard
        img={img31}
        date="17.04.23"
        title="Sirupy - jaký je mezi nimi rozdíl a čím sladit? "
        category="Všechno"
        link="/blog/sirupy"
      />
      <BlogPostsCard
        img={img30}
        date="17.04.23"
        title="Chcete si udržet váhu? Zvolte krabičkovou dietu"
        category="Všechno"
        link="/blog/chcete-udrzet-vahu-krabickovou-dietu"
      />
      <BlogPostsCard
        img={img29}
        date="16.04.23"
        title="Jaký je správný trojpoměr živin?"
        category="Všechno"
        link="/blog/jaky-je-spravny-trojpomer-zivin"
      />
      <BlogPostsCard
        img={img28}
        date="16.04.23"
        title="Oleje - jak je používat v kuchyni?"
        category="Všechno"
        link="/blog/oleje-jak-je-pouzivat-v-kuchyni"
      />
      <BlogPostsCard
        img={img27}
        date="16.04.23"
        title="Jsou krabičky vhodné pro kojící ženy?"
        category="Všechno"
        link="/blog/jsou-krabicky-vhodne-pro-kojici-zeny"
      />
      <BlogPostsCard
        img={img26}
        date="16.04.23"
        title="Low fat vs. low carb. Pomohou vám zhubnout?"
        category="Všechno"
        link="/blog/low-fat-low-carb-pomohou-zhubnout"
      />
      <BlogPostsCard
        img={img25}
        date="16.04.23"
        title="Potřebujete nabrat svaly? Krabičková strava je řešením"
        category="Všechno"
        link="/blog/potrebujete-nabrat-svaly-krabickova-strava-resenim"
      />
      <BlogPostsCard
        img={img24}
        date="09.04.23"
        title="Železo, proč je v našem jídelníčku důležité?"
        category="Všechno"
        link="/blog/zelezo-proc-je-v-dulezite"
      />
      <BlogPostsCard
        img={img23}
        date="09.04.23"
        title="Co je to jojo efekt a jak s ním zatočit"
        category="Všechno"
        link="/blog/co-je-to-jojo-efekt-a-jak-s-nim-zatocit"
      />
      <BlogPostsCard
        img={img22}
        date="09.04.23"
        title="Proč je krabičková dieta jedinečnou možností jak konečně zhubnout"
        category="Všechno"
        link="/blog/krabickova-dieta-jedinecnou-moznosti-jak-konecne-zhubnout"
      />
      <BlogPostsCard
        img={img21}
        date="29.03.23"
        title="Může za nadváhu konzumace sacharidů?"
        category="Všechno"
        link="/blog/muze-za-nadvahu-konzumace-sacharidu"
      />
      <BlogPostsCard
        img={img20}
        date="25.03.23"
        title="S jakým sportem nejrychleji zhubnu?"
        category="Všechno"
        link="/blog/s-jakym-sportem-nejrychleji-zhubnu"
      />
      <BlogPostsCard
        img={img19}
        date="08.02.23"
        title="Zdravé stravování - jak na něj?"
        category="Všechno"
        link="/blog/zdrave-stravovani"
      />
      <BlogPostsCard
        img={img18}
        date="08.02.23"
        title="5 tipů, jak rozpoznat výživový mýtus"
        category="Všechno"
        link="/blog/jak-rozpoznat-vyzivovy-mytus"
      />
      <BlogPostsCard
        img={img17}
        date="08.02.23"
        title="Novinka - vegetariánské menu NutritioPro"
        category="Všechno"
        link="/blog/vege-menu"
      />
      <BlogPostsCard
        img={img16}
        date="08.02.23"
        title="Vitaminy skupiny B"
        category="Všechno"
        link="/blog/vitamin-B"
      />
      <BlogPostsCard
        img={img15}
        date="08.02.23"
        title="O vitaminu D"
        category="Všechno"
        link="/blog/vitamin-D"
      />
      <BlogPostsCard
        img={img14}
        date="08.02.23"
        title="Opravdu vám keto dieta pomůže zhubnout?"
        category="Všechno"
        link="/blog/keto-dieta"
      />
      <BlogPostsCard
        img={img13}
        date="08.02.23"
        title="Proč se vám nedaří zhubnout?"
        category="Všechno"
        link="/blog/proc-se-vam-nedari-zhubnout"
      />
      <BlogPostsCard
        img={img12}
        date="16.05.22"
        title="Je možné zhubnout bez sportu?"
        category="Všechno"
        link="/blog/jidelnicek-hubnuti-bez-sportu"
      />
      <BlogPostsCard
        img={img11}
        date="16.05.22"
        title="Jak zhubnout 10 kilo a udržet si váhu?"
        category="Všechno"
        link="/blog/jak-zhubnout-10-kg"
      />
      <BlogPostsCard
        img={img9}
        date="16.05.22"
        title="Zdravá krabička jako ideální oběd do kanceláře"
        category="Všechno"
        link="/blog/zdrava-krabicka-denní-menu"
      />
      <BlogPostsCard
        img={img7}
        date="16.05.22"
        title="Účinná krabičková dieta v Praze"
        category="Všechno"
        link="/blog/krabickova-dieta-praha"
      />
      <BlogPostsCard
        img={img8}
        date="16.05.22"
        title="Jak efektivně a rychle zhubnout 5 kg?"
        category="Všechno"
        link="/blog/jak-rychle-zhubnout"
      />
      <BlogPostsCard
        img={img10}
        date="26.03.22"
        title="Zdravé krabičky v Praze: rozvoz zdarma"
        category="Všechno"
        link="/blog/zdrave-jidlo-praha"
      />
      <BlogPostsCard
        img={img6}
        date="26.03.22"
        title="Dostaňte se do formy díky zdravému stravování"
        category="Všechno"
        link="/blog/dostante-se-do-formy"
      />
      <BlogPostsCard
        img={img5}
        date="26.03.22"
        title="Jak na zdravé a udržitelné hubnutí"
        category="Všechno"
        link="/blog/zdrave-udrzitelne-hubnuti"
      />
      <BlogPostsCard
        img={img4}
        date="31.05.21"
        title="Krabičkování. Proč je krabičková dieta snadné řešení."
        category="Všechno"
        link="/blog/post-4"
      />
      <BlogPostsCard
        img={img3}
        date="20.05.21"
        title="S vodou je spojeno hodně mýtů. Co je vhodné  pít, kdy a v jakém množství?"
        category="Všechno"
        link="/blog/post-3"
      />
      <BlogPostsCard
        img={img2}
        date="20.05.21"
        title="Detox. Ano nebo ne?"
        category="Všechno"
        link="/blog/post-2"
      />
      <BlogPostsCard
        img={img1}
        date="20.04.21"
        title="Chia semínka. Super potravina nebo hype?"
        category="Všechno"
        link="/blog/post-1"
      />
    </div>
  </Container>
)

export default Posts
